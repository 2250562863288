import { type FC, type PropsWithChildren, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'flowbite-react';
import { HTTPError } from 'ky';
import { authProvider } from 'providers/authProvider';
import { Helmet } from 'react-helmet';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { type UserSignInData } from 'domains/api/postUserSignIn';
import { usePageTracking } from 'utils/ga';
import PublicLayout from 'components/templates/PublicLayout';

const SignInForm: FC = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UserSignInData>({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit: SubmitHandler<UserSignInData> = async (data) => {
    try {
      setLoading(true);
      await authProvider.signIn(data);
      setLoading(false);
      navigate('/analytics');
    } catch (error: unknown) {
      setLoading(false);
      if (error instanceof HTTPError) {
        toast.error('メールアドレスまたはパスワードが間違っています');
      }
    }
  };

  return (
    <form
      className="new_contact"
      id="new_contact"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="p-contact__content">
        <p className="p-contact__title">
          <span>
            <label className="p-contact__titleMain" htmlFor="email">
              メールアドレス
            </label>
          </span>
          <span className="p-contact__titleSupplement">( 必須 )</span>
        </p>
        <input
          className="!leading-9 !text-lg block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-300"
          type="email"
          id="email"
          {...register('email', { required: true })}
        />
        {errors.email && (
          <div className="error_messages">メールアドレスを入力してください</div>
        )}
      </div>
      <div className="p-contact__content">
        <p className="p-contact__title">
          <span>
            <label className="p-contact__titleMain" htmlFor="password">
              パスワード
            </label>
          </span>
          <span className="p-contact__titleSupplement">( 必須 )</span>
        </p>
        <input
          className="!leading-9 !text-lg block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-300"
          type="password"
          id="password"
          {...register('password', { required: true })}
        />
        {errors.password && (
          <div className="error_messages">パスワードを入力してください</div>
        )}
      </div>
      <div className="p-contact__content">
        <Button type="submit" color={'gray'} isProcessing={loading}>
          ログインする
        </Button>
      </div>
    </form>
  );
};

const SignIn: FC<PropsWithChildren> = () => {
  usePageTracking(); // ページビュー計測

  return (
    <PublicLayout>
      <Helmet>
        <title>ログイン: サイト分析、レポートはGrowth Support</title>
      </Helmet>

      <section
        className="max-w-screen-xl px-4 py-8 mx-auto lg:py-24 lg:px-6 text-center"
        id="contact"
      >
        <div className="container p-login">
          <header className="c-section__header--narrow">
            <h2 className="c-section__title">ログイン</h2>
          </header>
          <div className="c-section__body">
            <div className="p-contact">
              <div className="p-contact__container">
                <SignInForm />
              </div>
            </div>
          </div>
        </div>
      </section>
    </PublicLayout>
  );
};

export default SignIn;
